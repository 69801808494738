<template>
  <div class="userLogin">
    <new-title url="/" title="" />
    <div class="body1Style">
      <div class="pageTitle">
        账号密码设置
        <p>为了保证密码的安全性，密码至少应为8个字符，且同时 包含数字和字母</p>
      </div>
      <div class="bodyStyleDivStyle">
        <p class="bodyStyleDivStyleP">密码</p>
        <input
          v-if="isShowPwd"
          class="body1StyleInputStyle"
          type="text"
          v-model="password"
          placeholder="设置登录密码"
        />
        <input
          v-else
          class="body1StyleInputStyle"
          type="password"
          v-model="password"
          placeholder="设置登录密码"
        />
        <span class="isShowPwd" @click="isShowPwd = !isShowPwd">
          <img v-if="isShowPwd" src="../../assets/imgs/userpage/pwdHide.png" />
          <img v-else src="../../assets/imgs/userpage/pwdShow.png" />
        </span>
      </div>

      <div class="bodyStyleDivStyle">
        <p class="bodyStyleDivStyleP">密码</p>
        <input
          v-if="isShowPwdRepad"
          class="body1StyleInputStyle"
          type="text"
          v-model="repassword"
          placeholder="请再次输入"
        />
        <input
          v-else
          class="body1StyleInputStyle"
          type="password"
          v-model="repassword"
          placeholder="请再次输入"
        />
        <span class="isShowPwd" @click="isShowPwdRepad = !isShowPwdRepad">
          <img
            v-if="isShowPwdRepad"
            src="../../assets/imgs/userpage/pwdHide.png"
          />
          <img v-else src="../../assets/imgs/userpage/pwdShow.png" />
        </span>
      </div>
    </div>
    <div class="bottom1">
      <van-row type="flex" justify="space-around">
        <van-col span="24">
          <van-button
            class="btnStyle mainBackColor1"
            @click="submit"
            type="primary"
            >完成</van-button
          >
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import NewTitle from "../../components/title";

export default {
  name: "setPassword",
  components: { NewTitle },
  data() {
    return {
      flag: false, // 获取验证码 是否禁用
      count: 60, // 60s 倒计时
      timer: null, // 倒计时的 定时器

      openid: "",
		nation: 86,
      password: "", // 密码
      repassword: "", // 确认密码
      isShowPwd: false, // 是否显示密码
      isShowPwdRepad: false,
      isSubmit: false,
      refcode: this.$route.query.refcode,
    };
  },
  computed: {
    phoneTypeText() {
      this.phone = "";
      this.password = "";
      if (this.loginType === "number") {
        return {
          title: "手机号",
          placeholder: "请输入您的手机号",
        };
      }
      return {
        title: "邮箱",
        placeholder: "请输入您的邮箱",
      };
    },
    loginType() {
      if (this.$route.query && this.$route.query.loginType) {
        return this.$route.query.loginType;
      }
      return null;
    },
    account() {
      if (this.$route.query && this.$route.query.account) {
        return this.$route.query.account;
      }
      return null;
    },
    code() {
      if (this.$route.query && this.$route.query.code) {
        return this.$route.query.code;
      }
      return null;
    },
    type() {
      if (this.$route.query && this.$route.query.type) {
        return this.$route.query.type;
      }
      return null;
    },
    sms() {
      if (this.$route.query && this.$route.query.sms) {
        return this.$route.query.sms;
      }
      return null;
    },
    sid() {
      if (this.$route.query && this.$route.query.sid) {
        return this.$route.query.sid;
      }
      return null;
    },
  },
  methods: {
    switchLoginType() {
      this.loginType = this.loginType === "number" ? "email" : "number";
    },
    // onClickLeft  点击返回“设置”页面
    onClickLeft() {
      this.$router.push("uSet");
    },
    // 提示
    showAlert(msg) {
      return this.$dialog.alert({
        title: "提示",
        message: msg,
      });
    },
    checkPassWord(password) {
      //密码必须包含数字和字母
      var str = password;
      if (str === null || str.length < 8) {
        return false;
      }
      var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
      if (!reg.test(str)) return false;
      return true;
    },
    // 验证
    check() {
      if (this.password == "" || this.password.length < 8) {
        this.showAlert("密码不能为空且不能小于8位");
        return false;
      }
      if (this.repassword == "" || this.repassword.length < 8) {
        this.showAlert("请再次输入密码");
        return false;
      }
      if (this.password !== this.repassword) {
        this.showAlert("两次密码输入不一致");
        return false;
      }
      if (!this.checkPassWord(this.password)) {
        this.showAlert("密码必须包含数字和字母");
        return false;
      }
      return true;
    },
    // 提交
    submit() {
      if (!this.check()) return;
      if (this.isSubmit) return;
      this.isSubmit = true;
      let parameter = {
        refcode: this.refcode,
        phone: this.account,
        code: this.sms,
        repassword: this.repassword,
        password: this.password,
        sid: this.sid,
		nation: this.nation
      };
      // 手机注册
      let url = "/user/api/passport/registerWithPhone";
      // 邮箱注册
      if (this.loginType === "email") {
        url = "/user/api/passport/registerWithEmail";
        parameter.phone = "";
        parameter.email = this.account;
      }
      // 重置密码
      if (this.type === "resetpwd") {
        url = "/user/api/passport/resetpwd";
      }
      this.$post2(url, parameter)
        .then((res) => {
          this.isSubmit = false;
          this.$saveLoginSession(res);
          this.$router.push({
            path: "/newHome",
            query: {
              ifhave: false,
            },
          });
        })
        .catch((e) => {
          this.isSubmit = false;
          this.showAlert(e);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.userLogin {
  .newTitleTwo {
    background: #fff;
  }

  /* @import "../../assets/css/puliceStyle.css"; */

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
  }

  .pageTitle {
    font-size: 0.48rem;
    font-weight: Bold;
    margin-bottom: 1.2rem;
  }

  .pageTitle p {
    font-size: 0.24rem;
    margin-top: 0.44rem;
  }

  .isShowPwd {
    display: inline-block;
    float: right;
    margin-top: 0.2rem;
    width: 0.34rem;
    height: 0.28rem;
  }

  .isShowPwd img {
    width: 100%;
    height: 100%;
  }

  .linkLIne {
    font-size: 0.26rem;
    color: #333;
    margin-top: 0.64rem;
  }

  .body1StyleInputStyle {
    border: 0px;
    font-size: 0.28rem;
    width: 80%;
    line-height: 0.8rem;
  }

  .bodyStyleDivStyleP {
    margin: 0.56rem 0 0.2rem 0;
  }

  .bodyStyleDivStyle {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.1rem;
  }

  .body1Style {
    padding: 0.3rem;
  }

  .bottom1 {
    position: fixed;
    width: 100%;
    bottom: 0.2rem;
  }

  .bottom1 p {
    margin-left: 0.3rem;
    font-size: 0.26rem;
    color: #333;
  }
}
</style>
